import store from "@/store";
import config from "@/config";

/**
 * @description 节流
 * @param fn { Function } 方法
 * @param time { Number } 间隔时间
 * @return { Function } 节流处理的后的方法
 */
export const throttle = (fn: (...params: any[]) => void, time = 1000) => {
  let previousTime = new Date(0).getTime();
  return (...args: []) => {
    let nowTime = new Date().getTime();
    if (nowTime - previousTime > time) {
      previousTime = nowTime;
      return fn(args);
    }
  };
};

/**
 * @description 防抖
 * @param fn { Function } 方法
 * @param time { Number } 间隔时间
 * @return { Function } 防抖处理的后的方法
 */
export const debounce = (fn: (...params: any[]) => void, time = 1000): any => {
  let timer: any = null;
  return (...args: []) => {
    if (timer) clearTimeout(timer);

    timer = setTimeout(() => {
      timer = null;
      fn(args);
    }, time);
  };
};

/**
 * @description 数组扁平化
 * @param arr { Array } 扁平化对象
 * @return { Array } 扁平化后的数组
 */
export const flatten = (arr: any[]): any[] => {
  return arr.reduce((result, item) => {
    return result.concat(Array.isArray(item) ? flatten(item) : item);
  }, []);
};

/**
 * @description 分页请求
 * @param [page] { Number } 请求页码
 * @param [size] { Number } 请求单页数量
 * @return { Object } 该类的实例
 */
export class RequestPaging {
  private _page: number = 1;
  private _size: number = 10;
  private _loading: boolean = false;
  private _count: number = 0;
  private _lists: any[] = [];
  constructor(payload?: { page?: number; size?: number }) {
    if (payload?.page) this._page = payload.page;
    if (payload?.size) this._size = payload.size;
  }

  // 请求数据
  request(payload: { callback: any; params?: object }): Promise<any> {
    // 禁止并发请求
    if (this._loading) return Promise.reject();
    else this._loading = true;

    return payload
      .callback({
        page_no: this._page,
        page_size: this._size,
        ...payload.params,
      })
      .then((res: any) => {
        this._count = res?.count;
        this._lists = res?.lists;
        return Promise.resolve(res);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      })
      .finally(() => {
        this._loading = false;
      });
  }

  get page(): number {
    return this._page;
  }

  get size(): number {
    return this._size;
  }

  get loading(): boolean {
    return this._loading;
  }
  set loading(loading: boolean) {
    this._loading = loading;
  }
  set page(num: number) {
    this._page = num;
  }

  set size(num: number) {
    this._size = num;
  }

  get count() {
    return this._count;
  }

  get lists() {
    return this._lists;
  }
}

/**
 * @description 获取随机字符串
 * @param length { Number } 获取位数
 * @return { String } 随机字符串
 */
export const getRandomString = (length: number): string => {
  const dictionaries =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let result = "";

  for (let i = length; i > 0; --i) {
    const index = Math.floor(Math.random() * dictionaries.length);
    result += dictionaries[index];
  }

  return result;
};

/**
 * @description 获取不重复的id
 * @param length { Number } id的长度
 * @return { String } id
 */
export const getNonDuplicateID = (length: number = 8) => {
  let idStr = Date.now().toString(36);
  idStr += Math.random().toString(36).substr(3, length);
  return idStr;
};

/**
 * @description 复制到剪切板
 * @param value { String } 复制内容
 * @return { Promise } resolve | reject
 */
export const copyClipboard = (value: string) => {
  const elInput = document.createElement("input");

  elInput.setAttribute("value", value);
  document.body.appendChild(elInput);
  elInput.select();

  try {
    if (document.execCommand("copy")) return Promise.resolve();
    else throw new Error();
  } catch (err) {
    return Promise.reject(err);
  } finally {
    document.body.removeChild(elInput);
  }
};

/**
 * @description 时间格式化
 * @param dateTime { number } 时间错
 * @param fmt { string } 时间格式
 * @return { string }
 */
// yyyy:mm:dd|yyyy:mm|yyyy年mm月dd日|yyyy年mm月dd日 hh时MM分等,可自定义组合
export const timeFormat = (dateTime: number, fmt = "yyyy-mm-dd") => {
  // 如果为null,则格式化当前时间
  if (!dateTime) dateTime = Number(new Date());
  // 如果dateTime长度为10或者13，则为秒和毫秒的时间戳，如果超过13位，则为其他的时间格式
  if (dateTime.toString().length == 10) dateTime *= 1000;
  let date = new Date(dateTime);
  let ret;
  let opt: any = {
    "y+": date.getFullYear().toString(), // 年
    "m+": (date.getMonth() + 1).toString(), // 月
    "d+": date.getDate().toString(), // 日
    "h+": date.getHours().toString(), // 时
    "M+": date.getMinutes().toString(), // 分
    "s+": date.getSeconds().toString(), // 秒
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
      );
    }
  }
  return fmt;
};

/**
 * @description 图片完整域名
 * @param uri { string } 图片链接
 * @return { string }
 */
//
export const getImageUri = (uri: string = "") => {
  const oss_domain = store.state.app.config.oss_domain || "";
  return oss_domain + uri;
};

export const objectToQuery = (data: any) => {
  let _result = [];

  for (let key in data) {
    let value = data[key];
    if (value.constructor == Array) {
      value.forEach((_value) => {
        _result.push(key + "=" + _value);
      });
    } else {
      _result.push(key + "=" + value);
    }
  }

  return _result.join("&");
};

/**
 * 深拷贝
 * @param {any} target 需要深拷贝的对象
 * @returns {Object}
 */
export function deepClone(target: any) {
  if (typeof target !== "object" || target === null) {
    return target;
  }

  const cloneResult: any = Array.isArray(target) ? [] : {};

  for (const key in target) {
    if (Object.prototype.hasOwnProperty.call(target, key)) {
      const value = target[key];

      if (typeof value === "object" && value !== null) {
        cloneResult[key] = deepClone(value);
      } else {
        cloneResult[key] = value;
      }
    }
  }

  return cloneResult;
}

export function processInput(input: any) {
  if (typeof input === "string") {
    try {
      const obj = JSON.parse(input);
      return Object.values(obj).join(",");
    } catch (error) {
      return input;
    }
  } else if (typeof input === "object") {
    return Object.values(input).join(",");
  } else {
    return "Invalid input";
  }
}

export const getImageOssUrl = (url: string, size: number = 150) => {
  return `${url}?x-oss-process=image/resize,w_${size},h_${size}`;
};

export const getOssFileInfo = (file: any) => {
  return new Promise((resolve, reject) => {
    fetch(`${file.url}?x-oss-process=image/info`)
      .then((response) => response.json())
      .then((json) => {
        let img: any = {};
        img.name = file.name;
        img.url = file.url;
        img.size = file.size;
        img.width = json.ImageWidth.value;
        img.height = json.ImageHeight.value;
        img.format = json.Format.value;
        resolve(img);
      });
  });
};

export const otherGetRequest = (url = "", params: any, headers: any) => {
  return new Promise((resolve, reject) => {
    const queryString = new URLSearchParams(params).toString();
    fetch(
      `${
        process.env.NODE_ENV == "production" ? window.origin : config.baseURL
      }/platformapi${url}?${queryString}`,
      {
        method: "GET",
        headers: {
          ...{
            "Content-Type": "application/json",
            version: "3.0.0",
          },
          ...headers,
        },
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.code == 1) {
          resolve(res.data);
        } else {
          resolve(res.msg);
        }
      })
      .catch((err) => {
        resolve("error");
      });
  });
};

export const otherPostRequest = (url = "", params: any, headers: any) => {
  return new Promise((resolve, reject) => {
    fetch(
      `${
        process.env.NODE_ENV == "production" ? window.origin : config.baseURL
      }/platformapi${url}`,
      {
        method: "POST",
        headers: {
          ...{
            "Content-Type": "application/json",
            version: "3.0.0",
          },
          ...headers,
        },
        body: JSON.stringify(params),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.code == 1) {
          resolve(res.data);
        } else {
          resolve(res.msg);
        }
      })
      .catch((err) => {
        resolve("error");
      });
  });
};

export const randomString = (length: number, type = 2) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  switch (type) {
    case 1:
      characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      break;
    case 2:
      characters = "abcdefghijklmnopqrstuvwxyz0123456789";
      break;
    case 3:
      characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      break;
  }
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
export const getCurrentTime = (type: number) => {
  var date = new Date(); // 当前时间
  var year = date.getFullYear(); // 年
  var month = repair(date.getMonth() + 1); // 月
  var day = repair(date.getDate()); // 日
  var hour = repair(date.getHours()); // 时
  var minute = repair(date.getMinutes()); // 分
  var second = repair(date.getSeconds()); // 秒
  // 当前时间
  var curTime = "";
  switch (type) {
    case 1:
      curTime = `${year}${month}${day}`;
      break;
    case 2:
      curTime = `${year}${month}`;
      break;
    default:
      curTime = `${year}${month}${day}`;
      break;
  }
  return curTime;
  function repair(i: number) {
    if (i >= 0 && i <= 9) {
      return "0" + i;
    } else {
      return i;
    }
  }
};
